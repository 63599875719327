import React from 'react';
import './Hotel.css';
import { Helmet } from 'react-helmet';

function Hotel() {
  return (
    <>
      <Helmet>
        <title>Hôtel - Les Tilleuls</title>
        <meta name="description" content="Découvrez l'hôtel Les Tilleuls, ouvert du lundi au jeudi, avec des services sur réservation le week-end et des offres adaptées aux professionnels." />
      </Helmet>
      <section className="hotel-page">
        <div className="hotel-header">
          <h1>Notre Hôtel</h1>
          <p>Un lieu chaleureux pour vos séjours en Bourgogne-Franche-Comté</p>
        </div>
        <div className="hotel-content">
          <div className="hotel-text">
            <p>
              <strong>Les Tilleuls</strong> est ravi de vous accueillir dans un cadre paisible et authentique.
              Notre hôtel est ouvert du <strong>lundi</strong> au <strong>jeudi</strong> inclus. Les <strong>vendredis, samedis et dimanches</strong>, 
              nous proposons un accueil sur réservation pour garantir une expérience sur-mesure.
            </p>
            <p>
              Pour les professionnels en déplacement, nous offrons une formule <strong>soirée étape</strong> à seulement <strong>95€</strong>. 
              Cette formule inclut un dîner savoureux, une nuit confortable et un petit-déjeuner pour bien démarrer la journée.
            </p>
            <p>
              Laissez-vous séduire par nos chambres élégantes et bien équipées, idéales pour un séjour d’affaires ou une escapade détente. 
              Notre équipe est à votre disposition pour répondre à toutes vos attentes.
            </p>
          </div>
          <div className="hotel-image">
            <img src="/chambre.jpg" alt="Chambre de l'Hôtel Les Tilleuls" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Hotel;
