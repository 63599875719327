import React, { useState, useEffect } from 'react';
import './Homepage.css';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';


function Homepage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    '/background-3.webp',
    '/background-4.webp',
    '/background-2.webp',
    '/background-1.webp'
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  return (
    <>
      <Helmet>
        <title>Accueil - Les Tilleuls</title>
        <meta name="description" content="Bienvenue aux Tilleuls, votre destination idéale pour un séjour inoubliable en région Bourgogne-Franche-Comté." />
        <meta property="og:title" content="Accueil - Les Tilleuls" />
        <meta property="og:description" content="Découvrez notre hôtel, restaurant, et les attractions locales." />
        <meta property="og:image" content="/logo.png" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Hotel",
              "name": "Les Tilleuls",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "3 Rue Descourtives",
                "addressLocality": "Saint-Florentin",
                "postalCode": "89600",
                "addressCountry": "FR"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.5",
                "reviewCount": "89"
              }
            }
          `}
        </script>
      </Helmet>
      <section className="hero">
        <div className="slider">
          <div className="slides" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {slides.map((src, index) => (
              <img key={index} src={src} alt={`Slide ${index + 1}`} className="slide" />
            ))}
          </div>
          <button title="prev-arrow" className="prev" onClick={prevSlide}>&lt;</button>
          <button title="next-arrow" className="next" onClick={nextSlide}>&gt;</button>
        </div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1>Hotel & Restaurant</h1>
          </div>
          <div className="under-hero">
            <p>Un cadre chaleureux pour une détente plaisir</p>
            <div className="buttons">
              <button title="reservation">
                <NavLink to="/reservation">RÉSERVATION</NavLink>
              </button>
              <button title="chambres">
                <NavLink to="/hotel">CHAMBRES</NavLink>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="restaurant">
        <div className="restaurant-text">
          <h2>Découvrez la cuisine de Terroir</h2>
          <h3>Notre Restaurant</h3>
          <p>
            Pour des déjeuners en toute simplicité et convivialité. Repas en famille ou pour affaires...
            Le restaurant <strong><i>Les Tilleuls</i></strong> est l'endroit parfait. C’est dans une ambiance authentique
            et décontractée que vous serez reçus.
          </p>
          <p>
            <strong>Thomas</strong> se fera un plaisir de vous servir et vous conseiller sur les vins de la région
            ainsi que les vins naturels.
          </p>
        </div>
        <div className="restaurant-image">
          <LazyLoad height={255} offset={100}>
            <img src="/dish.webp" alt="Plat du Restaurant" className="dish-image" />
          </LazyLoad>
        </div>
      </section>

      {/* Nouvelle Section Hôtel */}
      <section className="hotel">
        <div className="hotel-image">
          <LazyLoad height={255} offset={100}>
            <img src="/hotel-room.webp" alt="Chambre d'Hôtel" className="hotel-image-content" />
          </LazyLoad>
        </div>
        <div className="hotel-text">
          <h2>Un Séjour Confortable et Élégant</h2>
          <h3>Notre Hôtel</h3>
          <p>
            Découvrez notre hôtel où chaque chambre est un havre de paix et de confort. Avec des équipements modernes
            et une atmosphère chaleureuse, nous vous garantissons un séjour inoubliable. Que ce soit pour une nuit
            ou une semaine, nous sommes là pour vous offrir le meilleur.
          </p>
          <p>
            <strong>Henda</strong> est disponible pour vous aider à planifier votre séjour et vous recommander
            les meilleures attractions locales pour enrichir votre expérience.
          </p>
        </div>
      </section>
    </>
  );
}

export default Homepage;
