import React from 'react';
import './Restaurant.css';
import { Helmet } from 'react-helmet';

function Restaurant() {
  return (
    <>
      <Helmet>
        <title>Restaurant - Les Tilleuls</title>
        <meta name="description" content="Découvrez notre restaurant aux Tilleuls, proposant une cuisine de terroir avec des formules savoureuses pour le midi et un menu raffiné le soir." />
      </Helmet>
      <section className="restaurant-page">
        <div className="restaurant-header">
          <h1>Notre Restaurant</h1>
          <p>Une expérience culinaire unique et chaleureuse</p>
        </div>
        <div className="restaurant-content">
          <div className="restaurant-text">
            <p>
              <strong>Les Tilleuls</strong> vous accueillent pour une expérience culinaire qui marie
              authenticité et convivialité. Notre équipe est ravie de vous proposer une cuisine de
              terroir préparée avec des produits locaux et de saison.
            </p>
            <p>
              <strong>Horaires :</strong> <br />
              Déjeuners : de <strong>11h30</strong> à <strong>13h30</strong> (uniquement sur réservation).<br />
              Dîners : de <strong>19h</strong> à <strong>21h</strong>.
            </p>
            <p>
              <strong>Midi :</strong> découvrez notre formule savoureuse à 19€, comprenant :
              <ul>
                <li>Entrée + Plat + Café</li>
                <li>ou Plat + Dessert + Café</li>
              </ul>
            </p>
            <p>
              <strong>Soir :</strong> nous vous proposons un menu complet à 28€ :
              <ul>
                <li>Entrée + Plat + Dessert</li>
              </ul>
            </p>
            <p>
              Chaque plat est soigneusement élaboré pour mettre en valeur les saveurs du terroir.
              Nous sommes heureux de vous conseiller sur les meilleurs accords mets et vins.
            </p>
          </div>
          <div className="restaurant-image">
            <img src="/restaurant.jpg" alt="Salle du restaurant Les Tilleuls" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Restaurant;
